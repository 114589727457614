.body{
  background-color: aliceblue;
}

.panel-container {
    display: flex;
    padding-top: 4rem;
    color: #1d3557;
    padding-bottom: 6rem;
  }
  
  .panel-button {
    width: 30%; 
    margin-right: 20px;
  }
  
  .panel-form {
    width: 70%; 
  }
  
  .welcome-panel{
    width: 40%;
  }
  
  .panel-btn{
    padding-left: 12px;
    padding-right: 12px;
  }
  
  .panel-btn ul{
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
  }
  
  .panel-btn ul li{    
    margin-bottom: 12px;
  }
  
  .panel-btn ul li:hover{
    background-color: #df3946;
    box-shadow: 0px 5px 28.5px 1.5px rgba(254, 35, 9, 0.2);
  
  }
  .panel-btn ul li {
    display: block;
    text-transform: uppercase;
    box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
    background-color: white;
    padding: 14px 20px;
    position: relative;
    border-radius: 5px;
    font-weight: 700;
  }
  
  
  .panel-btn ul li a {
    text-decoration: none;
    color: #1d3557;
  }
  
  .panel-btn ul:hover{
    color:white;
  }
  
  .black{
    color: black;
  }


  /* Panel Container */
.panel-container {
  display: flex;
  padding: 4rem 2rem;
  color: #1d3557;
  background-color: #f0f0f0;
}

/* Panel Button */
.panel-button {
  width: 30%; 
  margin-right: 2rem;
}

/* Panel Form */
.panel-form {
  width: 70%; 
}

/* Welcome Panel */
.welcome-panel {
  width: 40%;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Panel Button List */
.panel-btn ul {
  padding-left: 0;
  list-style-type: none;
}

.panel-btn ul li {
  margin-bottom: 1rem;
  padding: 1.2rem 2rem;
  border-radius: 6px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.panel-btn ul li:hover {
  background-color: #df3946;
  color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.panel-btn ul li a {
  text-decoration: none;
  color: inherit;
}

/* Additional Classes */
.black {
  color: black;
}

.panel-container {
  display: flex;
  justify-content: space-between;
  padding: 4rem;
  margin-left: 17rem;
  background-color: #f8f9fa; /* Light gray background */
}

.panel-button {
  width: 30%;
  margin-right: 2rem;
}

.panel-form {
  width: 65%; /* Adjusted to 65% for better balance */
}

.panel-widget-area {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.panel-list {
  padding-left: 0;
  list-style-type: none;
}

.panel-btn ul {
  padding-left: 0;
  margin-top: 1rem;
}

.panel-btn ul li {
  margin-bottom: 1rem;
  padding: 1.2rem 2rem;
  border-radius: 8px;
  font-weight: 600;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.panel-btn ul li:hover {
  background-color: #df3946; /* Reddish hover color */
  color: #fff; /* White text on hover */
}

.panel-btn ul li a {
  text-decoration: none;
  color: #1d3557;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panel-btn ul li .arrow-icn {
  display: flex;
  align-items: center;
}

.admin-slide-container {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.admin-slide-heading {
  color: #1d3557;
  margin-bottom: 1.5rem;
}

.admin-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  padding-top: 16px;
}

.admin-form div {
  display: flex;
  flex-direction: column;
}

.admin-form label {
  margin-bottom: 0.5rem;
}

.admin-form input[type="text"],
.admin-form input[type="number"],
.admin-form input[type="file"] {
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.admin-form button {
  padding: 1rem 2rem;
  background-color: #1d3557;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin-form button:hover {
  background-color: #0b1c3d; /* Darker shade of primary color on hover */
}

.admin-form button:not(:last-child) {
  margin-right: 1rem;
}

.admin-form button[type="button"] {
  background-color: #ccc;
  color: #333;
}

@media (max-width: 768px) {
  .panel-container {
    flex-direction: column;
  }

  .panel-button {
    width: 100%;
    margin-bottom: 2rem;
  }

  .panel-form {
    width: 100%;
  }
}

/* Banner */

.banner-panel input{
  padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.banner-panel button{
  padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.banner-panel input{
  padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.banner-panel button{
  padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}
/* Banner */

/* For Mobile */

.banner-video button{
  padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.banner-video input{
  padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}
/* For Mobile */









/* Panel.css */

.admin-panel-container {
  display: flex;
  height: 100vh; /* Full viewport height */
}

.admin-sidebar {
  width: 250px; /* Sidebar width */
  background-color: #2c3e50; /* Sidebar background color */
  color: #fff; /* Text color */
  padding-top: 20px;
}

.admin-sidebar-header {
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff; /* Divider line */
}

.admin-sidebar-header h3 {
  margin-bottom: 0;
}

.admin-sidebar-nav {
  list-style: none;
  padding: 0;
}

.admin-sidebar-nav li {
  padding: 10px 20px;
}

.admin-sidebar-nav a {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.admin-sidebar-nav a:hover {
  background-color: #34495e; /* Darker background on hover */
}

.admin-main {
  flex: 1; /* Take remaining space */
  padding: 20px;
}
/* Panel.css */

.admin-panel-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
}

.admin-header {
  background-color: #34495e; /* Header background color */
  color: #fff; /* Text color */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.admin-header-left {
  flex: 1; /* Take as much space as possible */
}

.admin-header h2 {
  margin: 0;
}

.admin-header-right {
  display: flex;
  align-items: center;
}

.admin-header-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}

.admin-header-nav li {
  margin-left: 20px;
}

.admin-header-nav a {
  color: #fff;
  text-decoration: none;
}

.admin-header-nav a:hover {
  text-decoration: underline;
}


/* AdminHeader.css */

.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #37474f;
  color: #fff;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-header__brand h1 {
  margin: 0;
  font-size: 1.5rem;
}

.admin-header__nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.admin-header__nav li {
  margin-right: 1rem;
}

.admin-header__nav a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.admin-header__nav a:hover {
  color: #bbdefb;
}


/* SidebarNav.css */

.sidebar {
  width: 250px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #263238;
  color: #fff;
  padding-top: 1rem;
}

.sidebar-header {
  text-align: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid #455a64;
}

.sidebar-header h3 {
  margin: 0;
  font-size: 1.5rem;
}

.sidebar-list {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;
}

.sidebar-link {
  display: block;
  color: #cfd8dc;
  text-decoration: none;
  padding: 0.8rem 1rem;
  transition: background-color 0.3s ease;
}

.sidebar-link:hover {
  background-color: #37474f;
  color: #fff;
}
/* SidebarNav.css */

.sidebar {
  width: 250px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #263238;
  color: #fff;
  padding-top: 1rem;
  overflow-y: auto; /* Enable scrolling if content exceeds height */
}

.sidebar-header {
  text-align: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid #455a64;
}

.sidebar-header h3 {
  margin: 0;
  font-size: 1.5rem;
}

.sidebar-list {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;
}

.sidebar-link {
  display: block;
  color: #cfd8dc;
  text-decoration: none;
  padding: 0.8rem 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.sidebar-link:hover {
  background-color: #37474f;
  color: #fff;
}

/* AdminDashboard.css */

.admin-dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.admin-header {
  background-color: #37474f;
  color: #fff;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-header__brand h1 {
  margin: 0;
  font-size: 1.5rem;
}

.admin-header__nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.admin-header__nav li {
  margin-right: 1rem;
}

.admin-header__nav a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.admin-header__nav a:hover {
  color: #bbdefb;
}

.sidebar {
  width: 250px;
  background-color: #263238;
  color: #fff;
  padding: 1rem;
}

.sidebar-header {
  text-align: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid #455a64;
}

.sidebar-header h3 {
  margin: 0;
  font-size: 1.5rem;
}

.sidebar-list {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;
}

.sidebar-link {
  display: block;
  color: #cfd8dc;
  text-decoration: none;
  padding: 0.8rem 1rem;
  transition: background-color 0.3s ease;
}

.sidebar-link:hover {
  background-color: #37474f;
  color: #fff;
}

.dashboard-boxes {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 4rem;
  margin-left:20rem;
}

.dashboard-box {
  text-align: center;
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 250px;
}

.box-icon {
  font-size: 3rem;
  display: block;
  margin-bottom: 1rem;
}

.box-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.box-description {
  color: #666;
}



.navbar-item-list {
  /* padding: 1rem 2rem;
    background-color: #1d3557;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease; */
    padding-top: 10px;
}

/* Basic styles for the buttons */
button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s, transform 0.3s;
  margin-right: 8px; /* Add space between buttons */
}

button:hover {
  background-color: #0056b3;
  transform: scale(1.1);
}

/* Animation for the Edit button */
.edit-button {
  position: relative;
  overflow: hidden;
}

.edit-button::after {
  content: '✏️';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  transition: transform 0.3s;
}

.edit-button:hover::after {
  transform: translate(-50%, -50%) scale(1);
}

/* Animation for the Delete button */
.delete-button {
  position: relative;
  overflow: hidden;
}

.delete-button::after {
  content: '🗑️';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  transition: transform 0.3s;
}

.delete-button:hover::after {
  transform: translate(-50%, -50%) scale(1);
}

/* Keyframe animation for the shake effect (optional) */
@keyframes shake {
  0% { transform: translateX(-2px); }
  25% { transform: translateX(2px); }
  50% { transform: translateX(-2px); }
  75% { transform: translateX(2px); }
  100% { transform: translateX(0); }
}

.delete-button:active {
  animation: shake 0.5s;
}
